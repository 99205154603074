import React from 'react';
import './SoftwareSkill.scss';
import { skillsSection } from '../../portfolio';
import { Fade } from 'react-reveal';

export default function SoftwareSkill() {
  return (
    // <div>
    //   <h1 className='skills-heading'>Skills</h1>
    //   <div className='software-skills-main-div'>
    //     <ul className='dev-icons'>
    //       {skillsSection.softwareSkills.map((skills, i) => {
    //         return (
    //           <li
    //             key={i}
    //             className='software-skill-inline'
    //             name={skills.skillName}
    //           >
    //             <i className={skills.fontAwesomeClassname}></i>
    //             <p>{skills.skillName}</p>
    //           </li>
    //         );
    //       })}
    //     </ul>
    //   </div>
    // </div>
    <Fade bottom duration={1000} distance='20px'>
      <h1 className='skills-heading' style={{ textAlign: 'center' }}>
        Skills
      </h1>
      <ul className='dev-icons'>
        {skillsSection.softwareSkills.map((skills, i) => {
          return (
            <li
              key={i}
              className='software-skill-inline'
              name={skills.skillName}
            >
              <i className={skills.fontAwesomeClassname}></i>
              <p>{skills.skillName}</p>
            </li>
          );
        })}
      </ul>
    </Fade>
  );
}
