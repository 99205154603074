import React from 'react';
import '../education/Education.scss';
import EducationCard from '../../components/educationCard/EducationCard';
import { leadershipInfo } from '../../portfolio';

export default function Education() {
  if (leadershipInfo.display) {
    return (
      <div className='education-section' id='education'>
        <h1 className='education-heading'>Leadership</h1>
        <div className='education-card-container'>
          {leadershipInfo.schools.map((school, index) => (
            <EducationCard key={index} school={school} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
